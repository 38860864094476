import React from 'react';
import { Layout } from 'antd';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from 'routes/404';
import Home from 'routes/Home';
import Career from 'routes/Career';
import Privacy from 'routes/Privacy';
import Terms from 'routes/Terms';

const { Content: AntContent } = Layout;

const Content = () => {
  return (
    <AntContent className='app-content'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/career' element={<Career />} />
          <Route path='/privacy-policy' element={<Privacy />} />
          <Route path='/terms-and-conditions' element={<Terms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AntContent>
  );
};

export default Content;