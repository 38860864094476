import React from 'react';
import { Layout as AntLayout } from 'antd';
import './index.css';
import Header from './header';
import Content from './content';
import Footer from './footer';

const Layout = () => {
  return (
    <AntLayout>
      <Header />
      <Content />
      <Footer />
    </AntLayout>
  );
};

export default Layout;