import React from 'react';
import './index.css';

const Privacy = () => {
  const privacyImgs = [
    require('@/assets/image/privacy/privacy-1.png'),
    require('@/assets/image/privacy/privacy-2.png'),
    require('@/assets/image/privacy/privacy-3.png'),
    require('@/assets/image/privacy/privacy-4.png'),
    require('@/assets/image/privacy/privacy-5.png'),
    require('@/assets/image/privacy/privacy-6.png'),
    require('@/assets/image/privacy/privacy-7.png'),
  ];
  return (
    <div className='app-container-privacy'>
      <img
        className='bg-img'
        src={require('@/assets/image/career/banner.png')}
        alt={'background image'}
      />
      <div className='content'>
        <img
          className='privacy-img'
          src={require('@/assets/image/privacy/privacy.png')}
          alt={'privacy'}
        />
        <div className='black-box'>
          {privacyImgs.map((img, index) => (
            <img
              key={index}
              className='privacy-image'
              src={img}
              alt={''}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Privacy;