import React from 'react';
import './index.css';

const Home = () => {
  return (
    <div className='app-container-home'>
      <img
        className='bg-img'
        src={require('@/assets/image/home/bg.png')}
        alt={'background image'}
      />
      <img
        className='right-img'
        src={require('@/assets/image/home/right-img.png')}
        alt={''}
      />
      <div className='content'>
        <img
          className='social-img'
          src={require('@/assets/image/home/social.png')}
          alt={'social'}
        />
      </div>
    </div>
  );
};

export default Home;