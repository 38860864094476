import React from 'react';
import './index.css';

const Terms = () => {
  const termsImgs = [
    require('@/assets/image/terms/terms-1.png'),
    require('@/assets/image/terms/terms-2.png'),
    require('@/assets/image/terms/terms-3.png'),
    require('@/assets/image/terms/terms-4.png'),
    require('@/assets/image/terms/terms-5.png'),
    require('@/assets/image/terms/terms-6.png'),
    require('@/assets/image/terms/terms-7.png'),
    require('@/assets/image/terms/terms-8.png'),
    require('@/assets/image/terms/terms-9.png'),
    require('@/assets/image/terms/terms-10.png'),
  ];
  return (
    <div className='app-container-terms'>
      <img
        className='bg-img'
        src={require('@/assets/image/career/banner.png')}
        alt={'background image'}
      />
      <div className='content'>
        <img
          className='terms-img'
          src={require('@/assets/image/terms/terms.png')}
          alt={'terms'}
        />
        <div className='black-box'>
          {termsImgs.map((img, index) => (
            <img
              key={index}
              className='terms-image'
              src={img}
              alt={''}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Terms;