import React from 'react';
import './index.css';

const Career = () => {
  const careerImgs = [
    require('@/assets/image/career/career-1.png'),
    require('@/assets/image/career/career-2.png'),
  ];
  return (
    <div className='app-container-career'>
      <img
        className='bg-img'
        src={require('@/assets/image/career/banner.png')}
        alt={'background image'}
      />
      <div className='content'>
        <img
          className='join-us-img'
          src={require('@/assets/image/career/join_us.png')}
          alt={'join us'}
        />
        <div className='black-box'>
          {careerImgs.map((img, index) => (
            <img
              key={index}
              className='career-image'
              src={img}
              alt={''}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Career;