import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';

const NotFound = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if (navigate) {
      setTimeout(() => {
        navigate('/');
      }, 2000);
    }
  }, [navigate]);

  return (
    <div className='error-container'>
      <div>
        <h1 className='title'>
          Page not found
        </h1>
      </div>
      <br />
      <Link to="/" className='go-home'>
        Go home
      </Link>
    </div>
  );
};

export default NotFound;